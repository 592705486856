.App-content {
  min-height: 100vh;
  display: flex;
  color: white;
}

.App-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease, filter 1s ease; /* Smooth transition for opacity and filter */
  opacity: 0; /* Initially hide the background */
  filter: blur(20px); /* Initially apply a blur effect */
}

.App-bg.loaded {
  opacity: 1; /* When loaded, show the background */
  filter: blur(0); /* Remove the blur effect */
}

input[type=text] {
  width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.checkmark-container {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #23272b;
  transition: all 0.25s ease-in;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
  background-color: #23272b;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmark {
  background-color: #23272b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
  left: 6.5px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

button {
  display: inline-block;
  margin-inline: auto;
  font-weight: 200;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.475rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}

button.bg-dark {
  background-color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.75);
}

button:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

button:hover {
  background-color: #23272b;
  border-color: #1d2124;
}

/* Ipad 12.9 portrait */
@media (max-width: 1366px) and (min-width: 1024px) and (orientation: portrait) {
  .headline {
    font-size: 2.5rem;
  }
}
@media (max-width: 575px) {
  .App-bg.App-bg-desktop {
    background: transparent !important;
  }
  .App-bg.App-bg-mobile {
    background-position: left;
  }
}
@media (min-width: 576px) {
  .App-bg.App-bg-desktop {
    background-attachment: fixed;
  }
  .App-bg.App-bg-mobile {
    background: transparent !important;
  }
}/*# sourceMappingURL=App.css.map */