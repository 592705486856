@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-weight: normal;
  font-family: Avenir;
  src: url(../public/fonts/avenir/Avenir-Roman.ttf)
}
@font-face {
  font-family: 'Bebas Noue';
  src: url(../public/fonts/bebas-neue/Bebas-Neue.ttf)
}
@font-face {
  font-weight: 600;
  font-family: Avenir;
  src: url(../public/fonts/avenir/Avenir-Medium.ttf)
}
@font-face {
  font-weight: 900;
  font-family: Avenir;
  src: url(../public/fonts/avenir/Avenir-Black.ttf)
}

body {
  margin: 0;
  font-family: avenir, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-bebas {
  font-family: 'Bebas Noue';
}